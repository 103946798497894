import React, { useState, useEffect } from "react";
import SoundBoard from "../../components/SoundBoard";
import { Box } from "@material-ui/core";
import {Typography} from "@mui/material";
import { MetaTags } from "react-meta-tags";

function Nick() {
  const [nickSounds, setNickSounds] = useState(null);

  useEffect(() => {
    let url =
      process.env.NODE_ENV === "development"
        ? "https://server.stavbot.com"
        : "https://server.stavbot.com";

    fetch(url + "/getAudio/nick")
      .then((response) => response.json())
      .then((data) => {
        setNickSounds(data);
      });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f0f0f0", // Light gray background color
          minHeight: "100vh", // Full height
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center content horizontally
          justifyContent: "center", // Center content vertically
          padding: 2, // Add padding
        }}
      >
         <MetaTags>
        <title>Stavbot - Talk to Nick</title>
        <meta
          name="description"
          content="Welcome to Stavbot! Press buttons and listen to his humorous responses."
        />
        <meta
          name="keywords"
          content="Stavbot, Adam, talk, questions, humor, responses, login"
        />
      </MetaTags>
        <Typography variant="h3" component="h1" gutterBottom>
          Nick
        </Typography>
        <SoundBoard sounds={nickSounds} />
      </Box>
    </>
  );
}

export default Nick;
