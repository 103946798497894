import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation, // Import useLocation hook
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import TextToSpeechNick from "../Pages/TalkNick";
import SignIn from "../Pages/SignIn";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import NavBar from "../components/NavBar";
import { auth } from "../firebase";
import Nick from "../Pages/Nick/index";
import Stav from "../Pages/Stav";
import Adam from "../Pages/Adam";
import Grid from "@mui/material/Grid";
import MetaTags from "react-meta-tags";

function App() {
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setAuthUser(user);
          setLoading(false);
        });
        return unsubscribe;
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!authUser && !loading) {
      navigate("/signin");
    }
  }, [authUser, navigate, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        marginBottom: "90px",
        minHeight: "100vh",
      }}
    >
      <MetaTags>
        <title>Stavbot - Talk to Cum</title>
        <meta
          name="description"
          content="Welcome to Stavbot! Talk to them and listen to his humorous responses. Log in and submit your questions to Adam."
        />
        <meta
          name="keywords"
          content="Stavbot, Adam, talk, questions, humor, responses, login"
        />
      </MetaTags>
      <Grid item style={{ flexGrow: 1 }}>
        <Routes>
          <Route path="/" element={<TextToSpeechNick />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/nick" element={<Nick />} />
          <Route path="/stav" element={<Stav />} />
          <Route path="/adam" element={<Adam />} />
        </Routes>
      </Grid>
      <Grid item>
        {/* Conditionally render NavBar if not on the login page */}
        {location.pathname !== "/signin" && <NavBar />}
      </Grid>
    </Grid>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
