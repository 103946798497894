import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Home, MicrowaveRounded } from '@mui/icons-material';
import { Money } from '@material-ui/icons';
import { FoodBank } from '@mui/icons-material';
import { MicRounded } from '@material-ui/icons';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Import the logout icon
import { getAuth, signOut } from 'firebase/auth'; // Import Firebase Auth modules

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate(); // Hook to programmatically navigate

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    // Navigate based on the selected value
    switch (newValue) {
      case 0:
        navigate('/'); // Update with the correct route for "Recents"
        break;
      case 1:
        navigate('/adam'); // Update with the correct route for "Favorites"
        break;
      case 2:
        navigate('/stav'); // Update with the correct route for "Favorites"
        break;
      case 3:
        navigate('/nick'); // Update with the correct route for "Nearby"
        break;
      default:
        break;
    }
  };

  // Implement the handleLogout function
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful, navigate to the desired page (e.g., login page)
        navigate('/signin');
      })
      .catch((error) => {
        // An error occurred during sign-out
        console.error(error);
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        bottom: 0,
        position: 'fixed',
        backgroundColor: 'white',
        zIndex: 100,
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleNavigationChange}
      >
        <BottomNavigationAction label="Home" icon={<Home />} />
        <BottomNavigationAction label="Adam" icon={<Money />} />
        <BottomNavigationAction label="Stav" icon={<FoodBank />} />
        <BottomNavigationAction label="Nick" icon={<MicrowaveRounded />} />
        <BottomNavigationAction
          label="Logout"
          icon={<ExitToAppIcon />}
          onClick={handleLogout} // Attach the handleLogout function to the onClick event
        />
      </BottomNavigation>
    </Box>
  );
}
