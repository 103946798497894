import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseApp = initializeApp({
  apiKey: "AIzaSyCFo9OYfwKIKlL2tiRBZX-nDgVIvwHGP0w",
  authDomain: "stavbot-92b88.firebaseapp.com",
  projectId: "stavbot-92b88",
  storageBucket: "stavbot-92b88.appspot.com",
  messagingSenderId: "103205067047",
  appId: "1:103205067047:web:a54dcbb47f1d0d45d93102",
  measurementId: "G-ZSHZRQJYD3"
});

// Initialize Firebase
const analytics = getAnalytics(firebaseApp);
const db = getFirestore(firebaseApp);
const perf = getPerformance(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, analytics, perf, auth };
export default firebaseApp;
