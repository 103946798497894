import React, { useState, useEffect } from "react";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import "./button.css";
import { Box } from "@material-ui/core";

let audio;

function playSound(location) {
  let url =
    process.env.NODE_ENV === "development"
      ? "https://server.stavbot.com"
      : "https://server.stavbot.com";

  audio = new Audio(url + location);
  audio.play();
  audio = "";
}

function useWindowDimensions() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return dimensions;
}

function SoundButton({ sound }) {
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;
  const buttonWidth = isMobile ? "150px" : "190px"; // Set a fixed width for mobile buttons
  const margin = isMobile ? "10px" : "10px";
  const fontSize = isMobile ? "12px" : "16px";

  return (
    <AwesomeButton
      style={{ width: buttonWidth, margin: margin, fontSize: fontSize }}
      type="primary"
      ripple
      onPress={() => {
        playSound(sound.fileLocation);
      }}
    >
      {sound.title}
    </AwesomeButton>
  );
}

function SoundBoard({ sounds }) {
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;

  if (!sounds) {
    return (
      <img
        src={"https://render.music.workers.dev/stav.webp"}
        alt="loading indicator"
      />
    );
  }

  const buttons = sounds.map((sound) => (
    <Box key={sound.title} mb="10px">
      <SoundButton sound={sound} />
    </Box>
  ));

  return (
    <Box
      maxWidth="100vw"
      display="flex"
      flexWrap="wrap"
      justifyContent="center" // Center the buttons within the container
      p="10px 0"
    >
      {buttons}
    </Box>
  );
}

export default SoundBoard;