import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";

const theme = createTheme({
  typography: {
    fontFamily: "Times New Roman",
    fontSize: 12,
  },
});

const App = () => {
  const [user, setUser] = useState(null);

  const auth = getAuth();

  const [message, setMessage] = useState("");
  const [summary, setSummary] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [username, setUsername] = useState("");
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleMessageChange = (e) => {
    const value = e.target.value;
    if (value.length > 100) {
      const limitedMessage = value.slice(0, 100);
      setMessage(limitedMessage);
    } else {
      setMessage(value);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Limit the message to 25 words

      // Send the limited message to the backend API
      const response = await axios.post("https://api.stavbot.com/api/interpret", {
        text: message,
        username: user ? user.displayName : "Anonymous",
      });

      // Extract the summary from the response
      const { summary } = response.data;
      setSummary(summary);

      // Generate audio using ElevenLabs API
      const audioResponse = await fetch(
        "https://api.elevenlabs.io/v1/text-to-speech/M6Tg6dgaTEln2kszXt9c",
        {
          method: "POST",
          headers: {
            "xi-api-key": "b6380125681f6a1dfd020d10ed491a4d",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: summary,
            voice_settings: {
              similarity_boost: 0.5,
              stability: 0.3,
              style: 0.25,
            },
          }),
        }
      );

      const audioBlob = await audioResponse.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
      setMessage("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: "100vh", // Apply height: 100vh to the parent element
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage:
              'url("https://lastfm.freetls.fastly.net/i/u/ar0/8c796eed7cb466d75caafda6f9b788e3.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%", // Use height: 100% instead of minHeight: 100vh
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Container maxWidth="sm">
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h4" align="center" gutterBottom>
                Ask nick a question and he will respond using the power of AI
                (Gay Sex)
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Message"
                    value={message}
                    onChange={handleMessageChange}
                  />
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Box>
              </form>
              {summary && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h4" align="center" gutterBottom>
                    Nick
                  </Typography>
                  <Typography variant="body1" align="center">
                    {summary}
                  </Typography>
                </Box>
              )}
              {audioUrl && (
                <Box sx={{ mt: 4, textAlign: "center" }}>
                  <Typography variant="h4" gutterBottom></Typography>
                  <audio src={audioUrl} controls />
                </Box>
              )}
            </Paper>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default App;
