import React, { useState, useEffect } from "react";
import SoundBoard from "../../components/SoundBoard";
import { Box, Typography } from "@mui/material";
import MetaTags from "react-meta-tags";

function Stav() {
  const [adamSounds, setAdamSounds] = useState(null);

  useEffect(() => {
    let url =
      process.env.NODE_ENV === "development"
        ? "https://server.stavbot.com"
        : "https://server.stavbot.com";

    fetch(url + "/getAudio/adam/")
      .then((response) => response.json())
      .then((data) => {
        setAdamSounds(data);
      });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#f0f0f0", // Light gray background color
        minHeight: "100vh", // Full height
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
        justifyContent: "center", // Center content vertically
        padding: 2, // Add padding
      }}
    >
      <MetaTags>
        <title>Stavbot - Talk to Adam</title>
        <meta
          name="description"
          content="Welcome to Stavbot! Talk to Adam and listen to his humorous responses. Log in and submit your questions to Adam."
        />
        <meta
          name="keywords"
          content="Stavbot, Adam, talk, questions, humor, responses, login"
        />
      </MetaTags>
      <Typography variant="h3" component="h1" gutterBottom>
        Adam
      </Typography>
      <SoundBoard sounds={adamSounds} />
    </Box>
  );
}

export default Stav;
