import React, { useState, useEffect } from "react";
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithPopup, getAuth } from "firebase/auth";
import GoogleButton from "react-google-button";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import MetaTags from "react-meta-tags";
import stavImage from './stav.jpg';
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    color: "black",
    fontFamily: "Roboto",

    backgroundImage: `url(${stavImage})`,
    
    
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",

  },
}));

export default function SignIn() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/");
      } else {
        setIsLoading(false);
      }
    });
    return unsubscribe;
  }, [navigate]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const handleSignInWithGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      navigate("/");
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  return (
    <div className={classes.container}>
      <MetaTags>
        <title>Stavbot - Talk to Nick Mullen</title>
        <meta
          name="description"
          content="Welcome to Stavbot, the place where you can talk to your favorite podcaster Nick Mullen. Log in with your Google account and submit your questions to Nick."
        />
        <meta
          name="keywords"
          content="Stavbot, Nick Mullen, podcaster, talk, questions, humor, wit, Google account, login"
        />
        <meta name="author" content="Stavbot Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </MetaTags>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          maxWidth: "80%",
        }}
      >
       
        <Box
          sx={{
            color: "white",
            fontSize: "1.5rem",
            fontFamily: "Courier",
            backgroundColor: "black",
            textAlign:"center",
            opacity: "0.80",

            borderRadius: "10px",
            padding: "10px",
            borderBottom: "20px",
            alignContent: "center",
            alignSelf: "center",
          }}
        >
          Use Advanced Techmologies to Talk to Nick Mullen in Real Time
        </Box>
<h1></h1>
        <GoogleButton onClick={handleSignInWithGoogle} />
        <h2></h2>
      </Box>

      {error && <p>{error}</p>}
    </div>
  );
}
