import React, { useState, useEffect } from "react";

import SoundBoard from "../../components/SoundBoard";
import {Box, Typography} from "@mui/material";


function Stav() {
  const [nickSounds, setNickSounds] = useState(null);

  useEffect(() => {
    let url =
      process.env.NODE_ENV === "development"
        ? "https://server.stavbot.com"
        : "https://server.stavbot.com";

    fetch(url + "/getAudio/stav")
      .then((response) => response.json())
      .then((data) => {
        setNickSounds(data);
      });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f0f0f0", // Light gray background color
          minHeight: "100vh", // Full height
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center content horizontally
          justifyContent: "center", // Center content vertically
          padding: 2, // Add padding
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom>
          Stav
        </Typography>
        <SoundBoard sounds={nickSounds} />
      </Box>
    </>
  );
}

export default Stav;
